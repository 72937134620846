<template>
  <div>
    <v-form ref="form" v-model="valid" @submit.prevent="submit">
      <slot name="title">
        <h5 class="mb-5 text-h5">Septic Maintenance</h5>
      </slot>
      <v-row>
        <v-col
          cols="12"
          :lg="
            formData.pumped || formData.inspected || formData.repaired
              ? '6'
              : '12'
          "
          md="12"
          sm="12"
        >
          <v-row>
            <v-col cols="12" sm="6">
              <v-menu
                ref="date"
                v-model="dialogs.datepicker"
                :close-on-content-click="false"
                transition="scale-transition"
                autocomplete="off"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :rules="rules.requiredRule"
                    dense
                    v-model="formData.serviceDate"
                    label="Septic Service Date"
                    persistent-hint
                    outlined
                    append-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="formData.serviceDate"
                  no-title
                  @input="dialogs.datepicker = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="checkbox-group d-flex flex-wrap">
                <v-checkbox
                  class="ml-4"
                  label="Pumped"
                  v-model="formData.pumped"
                ></v-checkbox>
                <v-checkbox
                  class="ml-4"
                  label="Inspected"
                  v-model="formData.inspected"
                ></v-checkbox>
                <v-checkbox
                  class="ml-4 repaired-checkbox"
                  label="Repaired"
                  v-model="formData.repaired"
                ></v-checkbox>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-select
                outlined
                v-model="formData.company"
                label="Company"
                dense
                :items="SEPTIC_OPTIONS.installedBy"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-text-field
                autofocus
                outlined
                type="number"
                label="Cost"
                v-model="formData.cost"
                dense
                :rules="rules.positiveNumber"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-textarea
            outlined
            dense
            label="Observed Condition"
            rows="1"
            auto-grow
          ></v-textarea>

          <v-row>
            <v-col cols="12" sm="8">
              <v-textarea
                rows="2"
                outlined
                label="Remarks"
                v-model="formData.remarks"
                dense
                auto-grow
              ></v-textarea>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                dense
                outlined
                label="Next Service Year"
                v-model="formData.next_service_year"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="fileupload-container">
                <div>
                  <v-checkbox
                    v-model="formData.report.exists"
                    @change="handleReport"
                    label="I have a report to store"
                  ></v-checkbox>
                </div>
                <v-slide-y-transition>
                  <file-upload
                    :showDescription="true"
                    :description.sync="files.description.report"
                    :error.sync="files.errors.report"
                    :report="formData.report"
                    v-show="formData.report.exists"
                    :file.sync="files.report"
                  ></file-upload>
                </v-slide-y-transition>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          lg="6"
          md="12"
          sm="12"
          v-if="formData.pumped || formData.inspected || formData.repaired"
        >
          <fieldset v-if="formData.pumped" class="light-border rounded-lg mb-2">
            <!-- <legend class="mx-auto px-3 title primary--text">Inspection</legend> -->
            <div class="pa-3">
              <v-row>
                <v-col cols="12" md="5">
                  <v-row>
                    <v-col cols="6" md="12">
                      <v-text-field
                        dense
                        outlined
                        label="Gallons Pumped"
                        type="number"
                        v-model="formData.pump.gallons_pumped"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="12">
                      <v-text-field
                        dense
                        outlined
                        label="Pumped by Name"
                        v-model="formData.pump.pumped_by_name"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="7">
                  <v-card max-width="450" class="mx-auto">
                    <v-container>
                      <div class="">
                        <div>
                          <span
                            >Effluent Tee Filter present?
                            <image-viewer
                              color="primary"
                              icon
                              :images="[
                                require('@/assets/img/EffluentTeeFilter.jpeg')
                              ]"
                              ><v-icon>mdi-information</v-icon></image-viewer
                            >
                          </span>
                        </div>
                        <div class="d-flex flex-wrap checkbox-group">
                          <v-checkbox
                            label="Yes"
                            value="yes"
                            v-model="formData.pump.effluent_tee_filter.present"
                          ></v-checkbox>
                          <v-checkbox
                            class="ml-5"
                            label="No"
                            value="no"
                            v-model="formData.pump.effluent_tee_filter.present"
                          ></v-checkbox>
                          <span
                            class="text-hint mt-2"
                            style="margin-left: 0.8rem"
                          >
                            (Skip this if you don’t know)
                          </span>
                        </div>
                      </div>
                      <div
                        v-if="
                          formData.pump.effluent_tee_filter.present == 'yes'
                        "
                        class=""
                      >
                        <div>
                          <span>If yes, was it cleaned?</span>
                        </div>
                        <div class="flex-checkbox">
                          <v-row>
                            <v-checkbox
                              label="Yes"
                              value="yes"
                              v-model="
                                formData.pump.effluent_tee_filter.cleaned
                              "
                            ></v-checkbox>
                            <v-checkbox
                              class="ml-5"
                              label="No"
                              value="no"
                              v-model="
                                formData.pump.effluent_tee_filter.cleaned
                              "
                            ></v-checkbox>
                          </v-row>
                        </div>
                      </div>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </fieldset>
          <fieldset
            v-if="formData.inspected"
            class="light-border rounded-lg mb-2"
          >
            <!-- <legend class="mx-auto px-3 title primary--text">Inspection</legend> -->
            <div class="pa-3">
              <v-radio-group v-model="formData.inspection.type">
                <v-row>
                  <v-radio
                    class="ml-4"
                    label="Basic Inspection"
                    value="basic"
                  ></v-radio>
                  <v-radio
                    class="ml-4"
                    style="margin-top: -6px"
                    label="Title 5 Inspection"
                    value="title_5"
                  ></v-radio>
                </v-row>
              </v-radio-group>
              <v-text-field
                outlined
                dense
                v-model="formData.inspection.inspection_summary"
                label="Inspection Summary"
              ></v-text-field>
              <v-checkbox
                class="mt-checkbox"
                v-model="formData.inspection.further_action"
                label="Further Action Needed"
              ></v-checkbox>
              <v-text-field
                v-if="formData.inspection.further_action"
                label="Action that is needed"
                outlined
                dense
                v-model="formData.inspection.action_needed"
              ></v-text-field>
              <div v-if="formData.inspection.type == 'title_5'">
                <p class="title">
                  Certification by
                  {{ formData.inspection.inspector_name }}
                  <v-btn
                    icon
                    href="https://www.mass.gov/doc/title-5-official-inspection-form-0/download"
                    target="_blank"
                    color="primary"
                    ><v-icon>mdi-information</v-icon></v-btn
                  >
                </p>
                <v-text-field
                  outlined
                  dense
                  v-model="formData.inspection.inspector_name"
                  label="Inspector Name"
                ></v-text-field>
                <v-row class="mt-2">
                  <v-col
                    cols="12"
                    sm="6"
                    v-for="(opt, index) in options.inspection_reports"
                    :key="index"
                  >
                    <v-checkbox
                      class="mx-3"
                      :label="`${index + 1}. ${opt}`"
                      :value="opt"
                      v-model="formData.inspection.report"
                      :style="{ 'margin-top': '-1.5rem' }"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </div>
              <div class="mt-n3">
                <v-checkbox
                  v-model="formData.inspection.report_file.exists"
                  label="I have a inspection report to store"
                ></v-checkbox>
              </div>
              <v-slide-y-transition>
                <file-upload
                  :showDescription="true"
                  v-show="formData.inspection.report_file.exists"
                  :file.sync="files.inspection_report"
                  :report="formData.inspection.report_file"
                  :description.sync="files.description.inspection_report"
                  :error.sync="files.errors.inspection_report"
                ></file-upload>
              </v-slide-y-transition>
            </div>
          </fieldset>
          <fieldset
            v-if="formData.repaired"
            class="light-border rounded-lg mb-2"
          >
            <!-- <legend class="mx-auto px-3 title primary--text">Inspection</legend> -->
            <div class="pa-3">
              <v-textarea
                dense
                rows="2"
                outlined
                label="Repair Description"
                v-model="formData.repair.description"
                auto-grow
              ></v-textarea>
            </div>
          </fieldset>
        </v-col>
      </v-row>
      <div class="ml-3 mb-4 text-center">
        <v-btn :loading="loading" @click="submit" color="primary"
          ><slot name="button">Submit</slot></v-btn
        >
        <slot name="cancel">
          <span @click="cancel()">
            <cancel-button></cancel-button>
          </span>
        </slot>
      </div>
    </v-form>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import utils from '@/mixins/utils'
import septic from '@/mixins/septic'
import audio from '@/mixins/audio'
export default {
  props: ['data'],
  mixins: [rules, utils, septic, audio],
  components: {
    FileUpload: () => import('@/components/FileUpload'),
    ImageViewer: () => import('@/components/ImageViewer')
  },
  mounted() {
    this.setData()
  },
  data: () => ({
    valid: true,
    defaultFormData: {
      serviceDate: '',
      inspection: { report_file: {} },
      pump: { effluent_tee_filter: {} },
      repair: {},
      report: {},
      pumped: true
    },
    formData: {},
    dialogs: {
      datepicker: false,
      effluent_tee: true
    },
    files: {
      report: null,
      inspection_report: null,
      errors: { report: false, inspection_report: false },
      description: { report: '', inspection_report: '' }
    },
    loading: null,
    options: {
      inspection_reports: [
        'Passes',
        'Conditionally Passes',
        'Needs Further Evaluation',
        'Fails'
      ]
    }
  }),
  beforeMount() {
    this.formData = this.copyObject(this.defaultFormData)
  },
  methods: {
    resetForm() {
      this.formData = this.copyObject(this.defaultFormData)
      this.$refs.form.resetValidation()
    },
    async uploadReport() {
      if (this.files.report) {
        let report = await this.uploadFile(this.files.report, {
          description: this.files.description.report,
          feature: 'septic',
          parcelID: this.$route.params.id || ''
        })
        this.formData.report = { ...this.formData.report, ...report }
        this.files.report = null
      }

      if (this.files.inspection_report) {
        let report = await this.uploadFile(this.files.inspection_report, {
          description: this.files.description.inspection_report,
          feature: 'septic',
          parcelID: this.$route.params.id || ''
        })
        this.formData.inspection.report_file = {
          ...this.formData.inspection.report_file,
          ...report
        }
        this.files.inspection_report = null
      }
    },
    async submit() {
      this.$refs.form.validate()
      if (this.valid) {
        this.loading = true

        if (!this.data) {
          this.formData.timestampCreated = new Date()
        }
        this.formData.timestampModified = new Date()

        await this.uploadReport()
        this.formData.report.description = this.files.description.report
        if (!this.formData.inspected) {
          this.formData.inspection = { report_file: {} }
        }
        if (!this.formData.pumped) {
          this.formData.pump = {}
        }
        if (!this.formData.repaired) {
          this.formData.repair = {}
        }
        this.loading = false
        this.$emit('submit', this)
        this.playSwordSwing()
      }
    },
    handleReport() {
      if (!this.formData.report.exists) {
        this.formData.report = {}
        this.files.report = null
      }
    },
    cancel() {
      if (!this.data) {
        this.resetForm()
      }
      this.$emit('cancel')
    },
    setData() {
      if (this.data) {
        let tmpData = {
          ...this.copyObject(this.defaultFormData),
          ...this.copyObject(this.data)
        }

        if (!tmpData.inspection.report_file) {
          tmpData.inspection.report_file = {}
        }
        if (!tmpData.pump.effluent_tee_filter) {
          tmpData.pump.effluent_tee_filter = {}
        }

        this.formData = tmpData

        if (this.formData.report.description) {
          this.files.description.report = this.formData.report.description
        } else {
          this.files.description.report = ''
        }

        if (this.formData.inspection.report_file.description) {
          this.files.description.inspection_report = this.formData.inspection.report_file.description
        } else {
          this.files.description.inspection_report = ''
        }
      }
    }
  },
  watch: {
    data() {
      this.setData()
    }
  }
}
</script>

<style scoped>
/* @media (max-width: 600px) {
  .flex-checkbox {
    margin-top: -200px !important;
  }
} */
.flex-checkbox {
  margin-left: 2rem;
  margin-top: -0.5rem;
}
.effluent-box {
  max-width: 38rem;
  padding: 4px;
  border: 1px solid #44719e67;
}
@media (min-width: 1264px) and (max-width: 1904px) {
  .repaired-checkbox {
    margin-top: -1rem;
  }
}
@media (min-width: 600px) and (max-width: 751px) {
  .repaired-checkbox {
    margin-top: -1rem;
  }
}
</style>
