<template>
  <v-card outlined :class="fill ? 'fill' : ''" max-width="100" min-height="80">
    <v-container class="text-center">
      <slot></slot>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    fill: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="sass">
@import ".../../../../design/_colors.scss"
.outlined
  border: thin dashed rgba(0, 0, 0, 0.2)
.fill
  color: white
  background-color: #8ca3a5

.active
  border: 2px solid $color-primary
</style>
