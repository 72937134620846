<template>
  <div>
    <div class="mt-5 mb-5">
      <div class="d-flex flex-wrap">
        <calendar-card outlined border-color="red" fill class="mt-2">
          <div>
            <span class="text-h7">Septic Calendar</span>
          </div>
        </calendar-card>

        <calendar-card
          class="mt-2"
          v-for="(year, index) in years"
          :key="index"
          :class="
            `mx-1 ${year > currentYear ? 'outlined' : ''} ${
              year == currentYear ? 'active' : ''
            }`
          "
          :style="{
            cursor:
              getYearInfo(year) && !getYearInfo(year).scheduled
                ? 'pointer'
                : 'auto'
          }"
        >
          <span class="strong">
            <div>
              {{ year }}
            </div>
          </span>
          <div>
            <div v-if="getYearInfo(year)">
              <div v-if="screenSize == 'xs' && getYearInfo(year).chipCount > 1">
                <v-icon @click="showEdit(year)">$septicTank</v-icon>
              </div>
              <div v-else>
                <div v-if="getYearInfo(year).scheduled">
                  <v-sheet
                    class="next-service cursor-pointer"
                    @click="showEdit(year)"
                    outlined
                    rounded
                    >Next service</v-sheet
                  >
                </div>
                <div v-else>
                  <v-chip
                    small
                    color="primary"
                    v-if="getYearInfo(year).pumped"
                    class="ml-1"
                    :outlined="getYearInfo(year).scheduled"
                    @click="showEdit(year)"
                    >Pump</v-chip
                  >
                  <v-chip
                    small
                    color="info"
                    v-if="getYearInfo(year).inspected"
                    class="ml-1"
                    :outlined="getYearInfo(year).scheduled"
                    @click="showEdit(year)"
                  >
                    {{
                      getYearInfo(year).inspection.type == 'title_5'
                        ? 'Title 5'
                        : 'Inspect'
                    }}
                  </v-chip>
                  <v-chip
                    @click="showEdit(year)"
                    small
                    color="error"
                    v-if="getYearInfo(year).repaired"
                    class="ml-1"
                    :outlined="getYearInfo(year).scheduled"
                    >Repair</v-chip
                  >
                </div>
              </div>
            </div>
          </div>
        </calendar-card>
      </div>
    </div>
  </div>
</template>

<script>
import CalendarCard from './CalendarCard'
import { mapGetters, mapMutations } from 'vuex'
export default {
  components: {
    CalendarCard
  },
  data() {
    return {
      swiperConfig: {
        slidesPerView: 'auto'
      }
    }
  },
  computed: {
    ...mapGetters({
      septicData: 'septicDataList'
    }),
    screenSize() {
      return this.$vuetify.breakpoint.name
    },
    currentYear() {
      const currentYear = new Date().getFullYear()
      return currentYear
    },
    years() {
      let yearList = []
      for (let i = this.currentYear - 5; i < this.currentYear; i++) {
        yearList.push(i)
      }
      for (let i = this.currentYear; i <= this.currentYear + 5; i++) {
        yearList.push(i)
      }
      return yearList
    }
  },
  methods: {
    ...mapMutations({
      setActiveSeptic: 'setActiveSeptic'
    }),
    getYearInfo(y) {
      let res = null
      for (let i = 0; i < this.septicData.length; i++) {
        let d = new Date(this.septicData[i].serviceDate)
        if (d == 'Invalid Date') {
          return res
        }
        if (d.getFullYear() == y) {
          res = this.septicData[i]
        } else if (this.septicData[i].next_service_year == y) {
          res = { ...this.septicData[i], scheduled: true }
        }
      }
      if (!res) {
        return res
      }
      let count = 0
      const k = ['inspected', 'repaired', 'pumped']
      k.forEach(_k => {
        if (res[_k]) {
          count++
        }
      })
      res.chipCount = count
      return res
    },
    showEdit(y) {
      let index = this.getYearInfo(y).index
      this.setActiveSeptic(index)
      this.$store.commit('setEditSeptic', true)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../../design/_colors.scss"
$light-bg: rgba(19, 219, 69, 0.07)
.next-service
  border: 1px solid $light-bg !important
  background-color: $light-bg
  color: $color-light-black
</style>
